import { Chip, Stack } from '@mui/material'
import { useRecordContext } from 'react-admin'
import PropTypes from 'prop-types'

const Tags = ({ setOpen, setTag, setQuote }) => {
  const record = useRecordContext()
  const isSalesRole = localStorage.getItem('permissions').includes('ROLE_SALES')
  const handleClick = (tag) => {
    setQuote(record.id)
    setTag(tag)
    setOpen(true)
  }

  const Tag = ({ tag }) => {
    if (tag.tagName === 'DISTRO' && isSalesRole) return

    return (
      <Chip
        label={tag.tagName}
        color="primary"
        onDelete={() => handleClick(tag['@id'])}
      />
    )
  }

  return (
    <Stack direction="row" spacing={1}>
      {record.tags.map((tag, index) => {
        return <Tag tag={tag} key={index} />
      })}
    </Stack>
  )
}

Tags.propTypes = {
  setOpen: PropTypes.func,
  setQuote: PropTypes.func,
  setTag: PropTypes.func,
  tag: PropTypes.any,
  index: PropTypes.number,
}

export default Tags
