import React from 'react'
import { useListContext } from 'react-admin'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'

const Multiselect = ({ items, defaultItem, filterName }) => {
  const { filterValues, setFilters, displayedFilters } = useListContext()
  const [itemsState, setItemsState] = React.useState(
    filterValues[filterName] || defaultItem
  )

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    const selectedValues = typeof value === 'string' ? value.split(',') : value
    setItemsState(selectedValues)

    if (setFilters) {
      setFilters(
        {
          ...filterValues,
          [filterName]: selectedValues,
        },
        displayedFilters
      )
    }
  }

  return (
    <FormControl sx={{ width: 200, height: 37 }}>
      <InputLabel id="multiple-checkbox-label" shrink>
        Status
      </InputLabel>
      <Select
        label="Status"
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={itemsState}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
      >
        {items.map((item) => (
          <MenuItem key={item.name} value={item.name}>
            <Checkbox checked={itemsState.includes(item.name)} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

Multiselect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultItem: PropTypes.arrayOf(PropTypes.string),
  filterName: PropTypes.string.isRequired,
}

export default Multiselect
