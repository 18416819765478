import {
  ChevronLeft,
  ChevronRight,
  LockOpen,
  ModeEdit,
} from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import { ListLive } from '@react-admin/ra-realtime'
import React, { useEffect, useRef, useState } from 'react'
import {
  BooleanInput,
  DatagridConfigurable,
  DateField,
  FilterButton,
  FunctionField,
  SelectInput,
  SortButton,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useRefresh,
  WithRecord,
} from 'react-admin'
import { currentUserHasRole } from '../Users'
import { getTagsList, handleLeadLocking } from '../../Utils/RestUtils'
import { DEFAULT_STATUS_LIST } from '../../Utils/ConstantsUtils'
import { checkIfUserHasExternalRole } from '../../Utils/HelperUtils'
import Multiselect from '../../Components/Form/Multiselect'

const ListQuotes = (props) => {
  const isAdmin = currentUserHasRole('ROLE_ADMIN')
  const isManager = currentUserHasRole('ROLE_MANAGER')
  const refresh = useRefresh()

  if (checkIfUserHasExternalRole()) {
    location.href = '/#/'
  }

  const rowStyles = (record) => {
    if (record) {
      if (record.scam === true) {
        return {
          backgroundColor: red[500],
          backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='30px' width='120px'><text x='10' y='20' fill='rgba(255,255,255,0.2)' font-family='arial' font-size='20'>SCAM</text></svg>")`,
        }
      }
      if (record.inUseBy && record.inUseBy !== '') {
        return {
          backgroundColor: grey[500],
          backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='30px' width='120px'><text x='10' y='20' fill='rgba(255,255,255,0.2)' font-family='arial' font-size='20'>IN USE</text></svg>")`,
        }
      }
    }
  }

  const dataFetchedRef = useRef(false)

  const [tags, setTags] = useState([])

  const getTagsFromApi = async () => {
    const response = await getTagsList()
    setTags([...tags, ...response])
  }

  useEffect(() => {
    //Prevent React local dev strict mode to load / call API data twice
    if (dataFetchedRef.current && tags.length === 1) return
    dataFetchedRef.current = true

    getTagsFromApi()
  }, [])

  const delayedRefreshEvent = () => {
    setTimeout(() => {
      refresh()
    }, 500)
  }

  // Added to recheck for locked records
  useEffect(() => {
    window.addEventListener('focus', delayedRefreshEvent)

    return () => {
      window.removeEventListener('focus', delayedRefreshEvent)
    }
  }, [])

  const superAdminListFilters = [
    <TextInput
      alwaysOn
      key={1}
      label="Registration"
      source="=registration"
      name="registration"
    />,
    <Multiselect
      alwaysOn
      key={2}
      label="status"
      source="status"
      name="status"
      items={DEFAULT_STATUS_LIST}
      defaultItem={['New inquiry']}
      filterName="leadStatuses"
    />,
    <TextInput
      key={3}
      label="Tel Number"
      source="telNumber"
      name="telNumber"
    />,
    <SelectInput
      select
      key={4}
      label="Tag name"
      source="tagName"
      name="tagName"
      optionText="tagName"
      optionValue="tagName"
      placeholder="Select a tag"
      choices={tags}
    />,
    <BooleanInput
      key={5}
      label="In use"
      source="inUse"
      name="inUse"
      options={{
        size: 'small',
      }}
    />,
    <TextInput
      key={6}
      label="Alt Tel Number"
      source="altTelNumber"
      name="altTelNumber"
    />,
    <TextInput key={7} label="Email Address" source="email" name="email" />,
    <TextInput key={8} label="Vehicle Make" source="make" name="make" />,
    <TextInput key={9} label="Vehicle Model" source="model" name="model" />,
    <TextInput key={10} label="Postcode" source="postcode" name="postcode" />,
    <TextInput
      key={8}
      label="Address Line 1"
      source="addressLine1"
      name="addressLine1"
    />,
    <TextInput key={11} label="Town" source="town" name="town" />,
    <TextInput
      key={12}
      label="Finance Ref."
      source="outstandingFinanceNotes"
      name="outstandingFinanceNotes"
    />,
    <BooleanInput
      key={13}
      label="Archived"
      source="archived"
      name="archived"
      options={{
        size: 'small',
      }}
    />,
    <BooleanInput
      key={14}
      label="Show zero cap"
      source="displayZeroCap"
      name="displayZeroCap"
      options={{
        size: 'small',
      }}
    />,
    <BooleanInput
      key={13}
      label="No notification set"
      source="displayNoNotification"
      name="displayNoNotification"
      options={{
        size: 'small',
      }}
    />,
  ]

  const salesListFilters = [
    <TextInput
      alwaysOn
      key={1}
      label="Registration"
      source="=registration"
      name="registration"
    />,
    <TextInput
      alwaysOn
      key={2}
      label="Additional Registration"
      source="=additionalRegistration"
      name="additionalRegistration"
    />,
    <TextInput
      key={3}
      label="Tel Number"
      source="telNumber"
      name="telNumber"
    />,
    <Multiselect
      alwaysOn
      key={4}
      label="status"
      source="status"
      name="status"
      items={DEFAULT_STATUS_LIST}
      defaultItem={['New inquiry']}
      filterName="leadStatuses"
    />,
    <TextInput select key={5} label="Tag name" source="tagName" name="tagName">
      {tags.map((tag) => (
        <MenuItem key={tag.tagName} value={tag.tagName}>
          {tag.tagName}
        </MenuItem>
      ))}
    </TextInput>,
    <TextInput
      key={3}
      label="Alt Tel Number"
      source="altTelNumber"
      name="altTelNumber"
    />,
    <TextInput key={6} label="Email Address" source="email" name="email" />,
    <TextInput key={7} label="Postcode" source="postcode" name="postcode" />,
    <TextInput
      key={4}
      label="Address Line 1"
      source="addressLine1"
      name="addressLine1"
    />,
    <TextInput key={8} label="Town" source="town" name="town" />,
    <BooleanInput
      key={9}
      label="Archived"
      source="archived"
      name="archived"
      size="small"
    />,
  ]

  const roleManagerFilters = [
    ...salesListFilters,
    <BooleanInput
      key={5}
      label="In use"
      source="inUse"
      name="inUse"
      options={{
        size: 'small',
      }}
    />,
  ]

  const unlockLead = async (leadId, method) => {
    let response = await handleLeadLocking(method, leadId)
    refresh()
  }

  const filtersToShow = () => {
    if (localStorage.getItem('permissions').includes('ROLE_ADMIN')) {
      return superAdminListFilters
    } else if (localStorage.getItem('permissions').includes('ROLE_MANAGER')) {
      return roleManagerFilters
    } else {
      return salesListFilters
    }
  }

  const PostPagination = () => {
    const { page, perPage, total, setPage } = useListContext()
    const nbPages = Math.ceil(total / perPage) || 1
    return (
      nbPages > 1 && (
        <Toolbar sx={{ width: '100%', display: 'block' }}>
          {page > 1 && (
            <Button
              sx={{
                float: 'left',
                display: 'flex',
              }}
              color="primary"
              key="prev"
              onClick={() => setPage(page - 1)}
            >
              <ChevronLeft />
              Prev
            </Button>
          )}
          {page !== nbPages && (
            <Button
              sx={{
                float: 'right',
                display: 'flex',
              }}
              color="primary"
              key="next"
              onClick={() => setPage(page + 1)}
            >
              Next
              <ChevronRight />
            </Button>
          )}
        </Toolbar>
      )
    )
  }

  return (
    <ListLive
      {...props}
      actions={
        <TopToolbar>
          <SortButton
            fields={['registrationDate', 'id', 'capValue', 'lastUpdate']}
          />
          <FilterButton />
        </TopToolbar>
      }
      perPage={25}
      pagination={<PostPagination />}
      sort={{ field: 'id', order: 'DESC' }}
      filterDefaultValues={{ archived: 0 }}
      filters={filtersToShow()}
      exporter={false}
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        rowStyle={rowStyles}
        sx={{}}
      >
        <WithRecord
          label="Lead ID"
          render={(record) => {
            if (record.inUseBy && record.inUseBy !== '') {
              return (
                <Typography>
                  #{record && record.originId}
                  <br />
                  [In use by: {record.inUseBy}]
                </Typography>
              )
            }
            return <Typography>#{record && record.originId}</Typography>
          }}
        />
        <TextField
          source="postcode"
          sortable={false}
          label="Postcode"
          sx={{ textTransform: 'uppercase' }}
        />
        <TextField
          source="registration"
          sortable={false}
          label="Reg"
          sx={{ textTransform: 'uppercase' }}
        />
        <WithRecord
          render={(record) => {
            if (record.registrationDate) {
              const date = new Date(record.registrationDate)

              return <Typography>{date.getFullYear()}</Typography>
            }

            return 'N/A'
          }}
          sortable={true}
          label="Reg Year"
        />
        <TextField source="make" sortable={false} label="Make" />
        <TextField source="model" sortable={false} label="Model" />
        <TextField source="status" sortable={false} label="Status" />
        <FunctionField
          sortable={false}
          label="Created at"
          render={() => (
            <React.Fragment>
              <DateField source="dateCreated" locales="en-GB" />
              <br />
              <Typography component="span">@&nbsp;</Typography>
              <DateField source="dateCreated" showTime showDate={false} />
            </React.Fragment>
          )}
        />
        <TextField source="capValue" sortable={false} label="CAP" />
        <WithRecord
          label="Assigned To"
          render={(record) => {
            let render = true
            let value = ''
            let key = '0'
            if (record.collectionAgentCompanyName && render) {
              value = record.collectionAgentCompanyName
              key = record.collectionAgentId
            }

            return <Typography key={key}>{value}</Typography>
          }}
        />
        <TextField source="mainUser" sortable={false} label="Main User" />
        <WithRecord
          label="Commission"
          render={(record) => {
            let render = true
            let key = '0'
            if (record.collectionAgentCompanyName && render) {
              const GBP = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
              })
              const commission = GBP.format(record.commission)
              return <Typography key={key}>{commission}</Typography>
            }
          }}
        />
        <WithRecord
          label=""
          render={(record) => {
            return (
              <ButtonGroup
                sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}
              >
                {!record.inUseBy && (
                  <React.Fragment>
                    <Tooltip title="Edit lead" position="right">
                      <Button
                        href={
                          '/#/quote-requests/' +
                          encodeURIComponent(
                            '/api/quote-requests/' + record.originId
                          )
                        }
                        onClick={async () => {
                          await handleLeadLocking('lock', record.originId).then(
                            delayedRefreshEvent()
                          )
                        }}
                        label="Edit lead"
                        className="quote-list__table-body_edit-btn"
                        style={{
                          fontSize: '0.6rem',
                        }}
                      >
                        <ModeEdit />
                      </Button>
                    </Tooltip>
                  </React.Fragment>
                )}
                {record.inUseBy &&
                  record.inUseBy !== '' &&
                  (isManager ||
                    isAdmin ||
                    record.inUseBy ===
                      localStorage.getItem('display_name')) && (
                    <React.Fragment>
                      <Tooltip title="Edit lead" position="right">
                        <Button
                          href={
                            '/#/quote-requests/' +
                            encodeURIComponent(
                              '/api/quote-requests/' + record.originId
                            )
                          }
                          label="Edit lead"
                          className="quote-list__table-body_edit-btn"
                        >
                          <ModeEdit />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Unlock" position="right">
                        <Button
                          label=""
                          startIcon={<LockOpen sx={{ mr: '-10px' }} />}
                          size="small"
                          onClick={() => {
                            unlockLead(record.originId, 'unlock')
                          }}
                          icon={null}
                        />
                      </Tooltip>
                    </React.Fragment>
                  )}
              </ButtonGroup>
            )
          }}
        />
      </DatagridConfigurable>
    </ListLive>
  )
}

export default ListQuotes
