import {
  fetchHydra as baseFetchHydra,
  HydraAdmin,
  hydraDataProvider,
  ResourceGuesser,
} from '@api-platform/admin'

import { parseHydraDocumentation } from '@api-platform/api-doc-parser'
import {
  AlternateEmailRounded,
  CurrencyPound,
  Gavel,
  LocalOffer,
  LocalShipping,
  MessageOutlined,
  Notifications,
  PeopleAlt,
  PointOfSale,
  RecentActors,
  Report,
  Settings,
  SupervisedUserCircle,
} from '@mui/icons-material'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { authProvider } from './authProvider'
import { customRoutes } from './Routes'

import MyLayout from './Components/Layout'
import Dashboard from './Pages/Dashboard'
import Login from './Pages/Login'
import { NotificationsList } from './Pages/Notifications'

import {
  CreateAdjustmentsSettings,
  EditAdjustmentsSettings,
} from './Pages/AdjustmentsSettings'
import EditEmailContent from './Pages/EmailContent/Edit'
import ListEmailContent from './Pages/EmailContent/List'
import { ListOwedFees } from './Pages/OwedFees'
import { MyList } from './Pages/MyList'
import CreateQuoteRequest from './Pages/QuoteRequests/Create'
import EditQuoteRequest from './Pages/QuoteRequests/Edit'
import ShowQuoteRequest from './Pages/QuoteRequests/Show'
import ListQuotes from './Pages/QuoteRequests/List'
import ListRules from './Pages/RuleSets'
import CreateRuleSet from './Pages/RuleSets/Create'
import EditRuleSet from './Pages/RuleSets/Edit'
import EditSMSContent from './Pages/SMSContent/Edit'
import ListSMSContent from './Pages/SMSContent/List'

import simpleRestProvider from 'ra-data-simple-rest'
import { Resource, useTheme } from 'react-admin'
import { dataProviderWithRealtime } from './dataProviderWithRealtime'
import { CreateCharity, EditCharity, ListCharities } from './Pages/Charity'
import {
  CreateCollectionAgent,
  EditCollectionAgent,
  ListCollectionAgent,
  ShowCollectionAgent,
} from './Pages/CollectionAgents'
import CreateCollectionAgentZone from './Pages/CollectionAgentZones/Create'
import ListCollectionAgentZoneCommissions from './Pages/CollectionAgentZones/List'
import InboundCall from './Pages/InboundCall'
import EditRuleSetRules from './Pages/RuleSetsRules/Edit'
import { CreateSetting, EditSetting, ListSettings } from './Pages/SiteSettings'
import { CreateTag, EditTag, ListTags } from './Pages/Tag'
import { CreateUser, EditUser, ListUsers } from './Pages/Users'
import { darkTheme } from './Theme/darkTheme'
import { lightTheme } from './Theme/lightTheme'
import ListReport from './Pages/Reporting/List'

TimeAgo.addDefaultLocale(en)

const entrypoint = process.env.REACT_APP_API_PLATFORM_ENDPOINT

const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: new Headers({
      Authorization:
        window.localStorage.getItem('token') !== null
          ? `Bearer ${window.localStorage.getItem('token')}`
          : '',
    }),
  })

const apiDocumentationParser = (entrypoint) =>
  parseHydraDocumentation(entrypoint, {
    headers: new Headers({
      Authorization:
        window.localStorage.getItem('token') !== null
          ? `Bearer ${window.localStorage.getItem('token')}`
          : '',
    }),
  }).then(
    ({ api }) => ({ api }),
    async (result) => {
      switch (result.status) {
        case 401:
          localStorage.removeItem('token')
          // Get the Refresh Token from local storage if it exists
          const currentRefreshToken = localStorage.getItem('refreshToken')

          if (currentRefreshToken) {
            const request = new Request(`${entrypoint}/tokens/refresh`, {
              method: 'POST',
              body: JSON.stringify({
                refreshToken: currentRefreshToken,
              }),
              headers: new Headers({
                'Content-Type': 'application/json',
              }),
            })

            const tokens = await fetch(request).then((response) => {
              if (response.status === 401) {
                localStorage.removeItem('refreshToken')

                return {
                  token: undefined,
                  refreshToken: undefined,
                }
              }

              return response.json()
            })

            if (
              tokens.token !== undefined &&
              tokens.refreshToken !== undefined
            ) {
              localStorage.setItem('token', tokens.token)
              localStorage.setItem('refreshToken', tokens.refreshToken)
            }
          }

          return Promise.resolve({
            api: result.api,
          })
        default:
          return Promise.resolve(result)
      }
    }
  )

const mainDataProvider = hydraDataProvider({
  entrypoint: entrypoint,
  httpClient: fetchHydra,
  useEmbedded: true,
  apiDocumentationParser: apiDocumentationParser,
})

const salvageBookingsEntrypoint =
  process.env.REACT_APP_API_PLATFORM_SALVAGE_BOOKINGS_ENDPOINT
const salvageBookingsDataProvider = simpleRestProvider(
  salvageBookingsEntrypoint
)

// Set data provider based on name of resource
const dataProvider = new Proxy(mainDataProvider, {
  get: (target, name) => {
    return (resource, params) => {
      if (resource && resource.startsWith('salvageBooking/')) {
        return salvageBookingsDataProvider[name](resource.substring(15), params)
      }
      // By default return SQ data provider
      return mainDataProvider[name](resource, params)
    }
  },
})

export const DPR = dataProviderWithRealtime(
  dataProvider,
  process.env.REACT_APP_MERCURE_ENDPOINT + '/.well-known/mercure',
  process.env.REACT_APP_MERCURE_JWT,
  process.env.REACT_APP_API_PLATFORM_ENDPOINT
)

const App = () => {
  const [theme, setTheme] = useTheme()
  let isUserASuperAdmin = false
  return (
    <HydraAdmin
      dashboard={Dashboard}
      dataProvider={DPR}
      entrypoint={entrypoint}
      customRoutes={customRoutes}
      authProvider={authProvider}
      disableTelemetry={true}
      mercure={true}
      loginPage={Login}
      requireAuth={true}
      theme={lightTheme}
      darkTheme={darkTheme}
      layout={MyLayout}
    >
      {(permissions) => {
        if (permissions.includes('ROLE_ADMIN')) {
          // Admin routes
          return [
            <>
              <ResourceGuesser
                name={'quote-requests'}
                options={{ showMenu: false, label: 'Quotes' }}
                create={CreateQuoteRequest}
                edit={EditQuoteRequest}
                show={ShowQuoteRequest}
                icon={RecentActors}
              />

              <ResourceGuesser
                name={'quotes'}
                options={{
                  showMenu: true,
                  label: 'Quotes',
                }}
                list={ListQuotes}
                icon={RecentActors}
              />

              <Resource
                name={'owed-fees'}
                options={{
                  showMenu: true,
                  label: 'Owed Fees',
                }}
                list={ListOwedFees}
                icon={PointOfSale}
              />

              <ResourceGuesser
                name={'collection-agents'}
                options={{
                  showMenu: true,
                  label: 'Collection Agents',
                }}
                create={CreateCollectionAgent}
                edit={EditCollectionAgent}
                list={ListCollectionAgent}
                show={ShowCollectionAgent}
                icon={LocalShipping}
              />

              <ResourceGuesser
                name={'collection-agents-zones'}
                options={{
                  showMenu: true,
                  label: 'Zone Commissions',
                }}
                create={CreateCollectionAgentZone}
                list={ListCollectionAgentZoneCommissions}
                icon={CurrencyPound}
              />

              <ResourceGuesser
                name={'lead-notifications'}
                options={{ showMenu: true, label: 'Notifications' }}
                list={NotificationsList}
                icon={Notifications}
              />

              <ResourceGuesser
                name={'settings/adjustments'}
                options={{
                  label: 'Adjustments Settings',
                  showMenu: false,
                }}
                create={CreateAdjustmentsSettings}
                edit={EditAdjustmentsSettings}
              />

              <ResourceGuesser
                name={'rules-adjustments'}
                options={{
                  label: 'Rule Adjustments',
                  showMenu: false,
                }}
              />

              <Resource
                name={'reporting'}
                options={{
                  showMenu: true,
                  label: 'Reporting',
                }}
                list={ListReport}
                icon={Report}
              />

              <ResourceGuesser
                name={'sms-content'}
                options={{
                  showMenu: true,
                  label: 'SMS Contents',
                  parent: 'Communications',
                }}
                icon={MessageOutlined}
                edit={EditSMSContent}
                list={ListSMSContent}
              />

              <ResourceGuesser
                name="email_contents"
                options={{
                  showMenu: true,
                  label: 'Email Contents',
                  parent: 'Communications',
                }}
                icon={AlternateEmailRounded}
                edit={EditEmailContent}
                list={ListEmailContent}
              />

              <ResourceGuesser
                name={'charities'}
                options={{
                  showMenu: true,
                  label: 'Charities',
                  parent: 'Configuration',
                }}
                list={ListCharities}
                edit={EditCharity}
                create={CreateCharity}
                icon={SupervisedUserCircle}
              />

              <ResourceGuesser
                name={'tags'}
                options={{
                  showMenu: true,
                  label: 'Tags',
                  parent: 'Configuration',
                }}
                list={ListTags}
                edit={EditTag}
                create={CreateTag}
                icon={LocalOffer}
              />

              <ResourceGuesser
                name={'users'}
                options={{
                  showMenu: true,
                  label: 'Users',
                  parent: 'Configuration',
                }}
                list={ListUsers}
                edit={EditUser}
                create={CreateUser}
                icon={PeopleAlt}
              />

              <ResourceGuesser
                name={'site_settings'}
                options={{
                  showMenu: true,
                  label: 'Settings',
                  parent: 'Configuration',
                }}
                list={ListSettings}
                edit={EditSetting}
                create={CreateSetting}
                icon={Settings}
              />

              <Resource
                name={'rules'}
                options={{
                  showMenu: true,
                  label: 'Rules',
                }}
                icon={Gavel}
                list={ListRules}
                edit={EditRuleSet}
                create={CreateRuleSet}
              />

              <ResourceGuesser
                name={'rules-sets-rules'}
                options={{ showMenu: false }}
                edit={EditRuleSetRules}
              />

              <Resource
                name={'inbound-call'}
                options={{ showMenu: false }}
                list={InboundCall}
              />
            </>,
          ]
        } else {
          // Non-admins routes
          return [
            <>
              <ResourceGuesser
                name={'quote-requests'}
                options={{ showMenu: false, label: 'Quotes' }}
                create={CreateQuoteRequest}
                edit={EditQuoteRequest}
                show={ShowQuoteRequest}
                list={false}
                icon={RecentActors}
              />

              <Resource
                name={'my-list'}
                options={{ label: 'My list' }}
                list={MyList}
                icon={Notifications}
              />

              <Resource
                name={'inbound-call'}
                options={{ showMenu: false }}
                list={InboundCall}
              />
            </>,
          ]
        }
      }}
    </HydraAdmin>
  )
}

export default App
