import { CardContent, Tooltip } from '@mui/material'

import 'react-datepicker/dist/react-datepicker.css'
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  List,
  TextField,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
  WithRecord,
} from 'react-admin'
import { ArrowDownward, Pageview } from '@mui/icons-material'
import React from 'react'
import DialButton from '../../Components/QuoteRequest/Modals/DialButton'
import { removeLeadReplyTagFromNotificationList } from '../../Utils/QuoteRequestUtils'
import { EXTERNAL_USER_ROLES } from '../../Utils/ConstantsUtils'

function MyList(props) {
  const refresh = useRefresh()
  const user_id = localStorage.getItem('user_id')
  const isUserASuperAdmin = localStorage
    .getItem('permissions')
    .includes('ROLE_ADMIN')

  const hasExternalRole = EXTERNAL_USER_ROLES.some((role) =>
    localStorage.getItem('permissions').includes(role)
  )

  const [update] = useUpdate()

  const GotoQuoteButton = () => {
    const record = useRecordContext()
    if (record) {
      const quoteID = record.quoteRequest.id

      const handleLinkClick = async () => {
        await removeLeadReplyTagFromNotificationList(record)

        update('lead-notifications', {
          id: record['@id'],
          data: { dismissed: true },
          previousData: record,
        }).then(() => {
          setTimeout(() => {
            refresh()
          }, 300)
        })

        window.open(
          '/#/quote-requests/%2Fapi%2Fquote-requests%2F' + quoteID,
          '_blank'
        )
        self.focus()
      }
      return (
        <Button variant="text" onClick={handleLinkClick}>
          <Pageview sx={{ mr: 0.5 }} />
          Quote
        </Button>
      )
    }
  }

  const PhoneContact = () => {
    const record = useRecordContext()
    const hasNumber = record.quoteRequest.leadContact.telNumber

    const handleRemoveTagReply = async () => {
      await removeLeadReplyTagFromNotificationList(record)

      update('lead-notifications', {
        id: record['@id'],
        data: { dismissed: true },
        previousData: record,
      }).then(() => {
        setTimeout(() => {
          refresh()
        }, 300)
      })
    }

    return (
      <React.Fragment>
        {hasNumber ? (
          <WithRecord
            label="Tel"
            render={(record) => {
              if (record.inUseBy && record.inUseBy !== '') {
                return <></>
              }
              return (
                <DialButton
                  number={record.quoteRequest.leadContact.telNumber}
                  goToEditPage={true}
                  originId={record.quoteRequest.id}
                  addMarginTop={false}
                  removeReplyTagFunc={handleRemoveTagReply}
                />
              )
            }}
          />
        ) : null}
      </React.Fragment>
    )
  }

  const MuteInboxButton = () => {
    const record = useRecordContext()
    const notify = useNotify()
    const refresh = useRefresh()

    const handleMuteClick = async (v) => {
      update('lead-notifications', {
        id: record['@id'],
        data: { isDropped: true },
        previousData: record,
      }).then(() => {
        setTimeout(() => {
          notify('Successfully dropped down', { type: 'success' })
          refresh()
        }, 300)
      })
    }

    return (
      <Button onClick={() => handleMuteClick(record.originId)}>
        <Tooltip title="Drop down">
          <ArrowDownward />
        </Tooltip>
      </Button>
    )
  }

  return (
    <CardContent>
      <h3 style={{ textAlign: 'center' }}>Notifications - Action required</h3>
      <List
        resource="lead-notifications"
        actions={null}
        filter={{
          displayDropped: true,
          'admin.id': user_id,
          dismissed: false,
          noteType: 1,
        }}
        sort={{ field: 'targetTimestamp', order: 'ASC' }}
        perPage={50}
        fullWidth
        title=" "
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Quote#" source="quoteRequest.id" />
          <TextField label="Status" source="quoteRequest.status" />
          <BooleanField label="Archived" source="quoteRequest.archived" />
          <TextField
            label="Registration"
            source="quoteRequest.leadContact.vehicle.registration"
          />
          <DateField
            label="Due time"
            source="targetTimestamp"
            showTime={true}
            locale="en-GB"
            sortable={false}
          />
          <TextField label="Reminder" source="payload" />
          <TextField label="Main user" source="admin.displayName" />
          <PhoneContact
            label="Tel"
            source="quoteRequest.leadContact.telNumber"
          />
          <WithRecord
            render={(record) => {
              if (
                isUserASuperAdmin ||
                (record.admin && record.admin.id == user_id && !hasExternalRole)
              ) {
                return <MuteInboxButton />
              }
              return <></>
            }}
          />
          <GotoQuoteButton />
        </Datagrid>
      </List>
      <h3 style={{ textAlign: 'center' }}>Notifications - Upcoming</h3>
      <List
        resource="lead-notifications"
        actions={null}
        filter={{
          noteType: 1,
          'admin.id': user_id,
          dismissed: false,
          isDropped: false,
          'targetTimestamp[gt]': Math.floor(new Date().getTime() / 1000),
        }}
        sort={{ field: 'targetTimestamp', order: 'ASC' }}
        perPage={50}
        fullWidth
        title=" "
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Quote#" source="quoteRequest.id" />
          <TextField label="Status" source="quoteRequest.status" />
          <BooleanField label="Archived" source="quoteRequest.archived" />
          <TextField
            label="Registration"
            source="quoteRequest.leadContact.vehicle.registration"
          />
          <DateField
            label="Due time"
            source="targetTimestamp"
            showTime={true}
            locale="en-GB"
            sortable={false}
          />
          <TextField label="Reminder" source="payload" />
          <TextField label="Main user" source="admin.displayName" />
          <PhoneContact
            label="Tel"
            source="quoteRequest.leadContact.telNumber"
          />
          <WithRecord
            render={(record) => {
              if (
                isUserASuperAdmin ||
                (record.admin && record.admin.id == user_id && !hasExternalRole)
              ) {
                return <MuteInboxButton />
              }
              return <></>
            }}
          />
          <GotoQuoteButton />
        </Datagrid>
      </List>
    </CardContent>
  )
}

export default MyList
