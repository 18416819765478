import { CurrencyPoundRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useState, useEffect } from 'react'
import {
  DateTimeInput,
  EditBase,
  Form,
  NumberInput,
  SelectInput,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  WithRecord,
} from 'react-admin'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import { getAvailableStatuses } from '../../../../Utils/HelperUtils'
import ArchiveLeadButton from '../../Actions/ArchiveLeadButton'
import DuplicateLeadButton from '../../Actions/DuplicateLeadButton'
import LeadSourceButton from '../../Actions/LeadSourceButton'
import MTButton from '../../Actions/MTButton'
import MarkScamButton from '../../Actions/MarkScamButton'
import DateTimePickerValue from '../LatestContact'
import { sendLog } from '../../../../Class/AuditLog'
import { getAdminUsers, handleLeadLocking } from '../../../../Utils/RestUtils'
import { DEFAULT_STATUS_LIST } from '../../../../Utils/ConstantsUtils'
import { reassignBookingAgent } from '../../../../Utils/QuoteRequestUtils'

const LeadIDPanel = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const [tooGoodToScrap, setTooGoodToScrap] = useState()
  const [initialLoad, setInitialLoad] = useState(true)
  const [adminUsers, setAdminUsers] = useState()
  const [assignedTo, setAssignedTo] = useState()
  const user_id = localStorage.getItem('user_id')

  const tooGood = record.leadContact.vehicle.tooGoodToScrap

  if (tooGood !== tooGoodToScrap) {
    setTooGoodToScrap(tooGood)
  }

  const leadStatuses = getAvailableStatuses(record.status)

  const handleAssignedToChange = async (e) => {
    let result = await reassignBookingAgent({
      quoteId: record['@id'],
      userId: e.target.value,
    })

    if (result.status === 'success') {
      notify(result.message, { type: 'success' })
      refresh()
    } else {
      notify(result.error_msg, { type: 'error' })
    }
  }

  const isUserASuperAdmin = localStorage
    .getItem('permissions')
    .includes('ROLE_ADMIN')

  useEffect(() => {
    // Show getNext users a toast notification for 'Offer accepted'
    if (record.status === 'Offer accepted' && !isUserASuperAdmin) {
      notify('This lead is in Offer Accepted! 🎉', {
        type: 'success',
      })
    }

    // Lock lead here
    if (user_id != 17) {
      if (record) {
        if (record.inUse && record.inUse.id != user_id && !isUserASuperAdmin) {
          notify(
            'This lead is already in use by: ' + record.inUse.displayName,
            { type: 'error' }
          )
          location.href = isUserASuperAdmin ? '/#/quotes' : '/#/'
        } else {
          handleLeadLocking('lock', record.originId)
          sendLog(record.originId, 'Shown Lead')
          if (!adminUsers && initialLoad) {
            const getSelectOptions = async () => {
              const admins = await getAdminUsers()
              setAdminUsers(admins)
            }

            getSelectOptions()
          }
          setInitialLoad(false)

          return () => {
            sendLog(record.originId, 'Logged out of lead')
            handleLeadLocking('unlock', record.originId)
          }
        }
      }
    }
  }, [])

  let assigned =
    record.leadContact.vehicle.vehicleCollection &&
    record.leadContact.vehicle.vehicleCollection.offerSelected &&
    record.leadContact.vehicle.vehicleCollection.offerSelected.collector

  const assignedStyle = {
    backgroundColor: 'green',
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
  }

  return (
    <EditBase>
      <Form>
        <Grid container spacing={1} sx={{ marginBottom: '2px' }}>
          <Grid item flex={2}>
            <DuplicateLeadButton />
          </Grid>
          <Grid item flex={1}>
            {assigned && isUserASuperAdmin && (
              <FormControl sx={{ width: '100%' }} size="small">
                <InputLabel id="approved-by-label">Assigned to</InputLabel>
                <Select
                  labelId="approved-by-label"
                  name="leadContact.vehicle.vehicleCollection
                          .assignedByAdmin"
                  defaultValue="default"
                  label="Assigned to"
                  value={
                    record.leadContact.vehicle.vehicleCollection
                      ? record.leadContact.vehicle.vehicleCollection
                          .assignedByAdmin['@id']
                      : 'default'
                  }
                  onChange={handleAssignedToChange}
                >
                  <MenuItem disabled value="default">
                    AssignedTo?
                  </MenuItem>
                  {adminUsers &&
                    adminUsers.map((user) => {
                      return (
                        <MenuItem key={user.id} value={user.id}>
                          {user.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            )}
            {!assigned && (
              <Button onClick={refresh} sx={{ mb: 1.5 }} fullWidth>
                Update
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextInput
              label="Quote ID"
              readOnly={true}
              disabled={true}
              source="originId"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={1}>
            <NumberInput
              disabled={true}
              source="leadContact.vehicle.vehicleSalvage.chosenValue"
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <CurrencyPoundRounded fontSize="small" />
                  </InputAdornment>
                ),
              }}
              label="Price"
              defaultValue="0"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={1}>
            <NumberInput
              disabled={true}
              source="leadContact.vehicle.vehicleSalvage.calcCommission"
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <CurrencyPoundRounded fontSize="small" />
                  </InputAdornment>
                ),
              }}
              label="Commission"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <FormControl sx={{ width: '100%' }} size="small">
              <SelectInput
                alwaysOn
                select
                key={2}
                label="Lead Status"
                source="status"
                name="status"
                placeholder="Select a status"
                choices={DEFAULT_STATUS_LIST}
                onChange={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </FormControl>
          </Grid>
          <Grid item flex={1}>
            <Box sx={{ my: 1 }}>
              <DateTimeInput
                label="Lead Created"
                readOnly={true}
                disabled={true}
                source="dateCreated"
                onBlur={async (e) =>
                  await validateAutoSaveSingleInput(
                    record,
                    e.target,
                    notify,
                    refresh
                  )
                }
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item flex={1}>
          <DateTimePickerValue />
        </Grid>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextInput
              label="Lead Source"
              readOnly={true}
              disabled={true}
              source="leadContact.leadContactAttributes.leadSource.title"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={1}>
            <WithRecord
              label="Assigned To"
              render={(record) => {
                let render = true
                let value = ''
                let style = {}
                if (assigned && render) {
                  render = false
                  value =
                    record.leadContact.vehicle.vehicleCollection.offerSelected
                      .collector.collectionAgentCompanyName
                  style = assignedStyle
                }

                return (
                  <TextField
                    key={1}
                    label="Assigned To"
                    readOnly={true}
                    disabled={true}
                    value={value}
                    InputProps={{ style: style }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: '-4px' }}>
          <Grid item flex={2}>
            <LeadSourceButton />
          </Grid>
          <Grid item flex={2}>
            <ArchiveLeadButton />
          </Grid>
          <Grid item flex={2}>
            <Grid container spacing={1}>
              <Grid item flex={1}>
                <MarkScamButton />
              </Grid>
              <Grid item flex={1}>
                <MTButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </EditBase>
  )
}

export default LeadIDPanel
