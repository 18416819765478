import React from 'react'
import { Grid, Typography } from '@mui/material'
import BalanceButton from './BalanceButton'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'

const BalanceOutstanding = ({ sb, setPopupPanelName }) => {
  const record = useRecordContext()

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          sx={{
            fontWeight: '700',
            letterSpacing: '0.46px',
            marginRight: '5px',
            textTransform: 'uppercase',
          }}
        >
          Outstanding:
        </Typography>
        <Typography
          sx={{
            fontWeight: '700',
            letterSpacing: '0.46px',
            textTransform: 'capitalize',
          }}
        >
          {new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(sb.payments.outstandingBalance)}
        </Typography>
      </Grid>
      {(record.deposit &&
        (sb.payments.deposit == null ||
          sb.payments.deposit.payment_paid == null)) ||
      (record.leadContact.vehicle.vehicleCommercial &&
        record.leadContact.vehicle.vehicleCommercial.commercialPurchase &&
        !record.quoteRequestInvoices[0]) ||
      (sb.payments.outstandingBalance !== 0 &&
        (record.quotePaymentDetails.acc === '00000000' ||
          record.quotePaymentDetails.sort === '000000')) ? (
        <>
          {record.leadContact.vehicle.vehicleCommercial &&
            record.leadContact.vehicle.vehicleCommercial.commercialPurchase &&
            !record.quoteRequestInvoices[0] && (
              <Grid item xs={12} sx={{ marginTop: 0.5 }}>
                <Typography
                  sx={{
                    fontWeight: '700',
                    letterSpacing: '0.46px',
                    marginRight: '5px',
                    textTransform: 'uppercase',
                    textDecoration: 'underline',
                    color: 'red',
                  }}
                >
                  <hr />
                  VAT INVOICE MUST BE UPLOADED FOR COMMERCIAL PURCHASES
                </Typography>
              </Grid>
            )}
          {sb.payments.outstandingBalance !== 0 &&
            (record.quotePaymentDetails.acc === '00000000' ||
              record.quotePaymentDetails.sort === '000000') && (
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: '700',
                    letterSpacing: '0.46px',
                    marginRight: '5px',
                    textTransform: 'uppercase',
                    textDecoration: 'underline',
                    color: 'red',
                  }}
                >
                  <hr />
                  CHECK ACCOUNT DETAILS
                </Typography>
              </Grid>
            )}
          {
            // No deposit paid
            record.deposit &&
              (sb.payments.deposit == null ||
                sb.payments.deposit.payment_paid == null) && (
                <Grid item xs={12} sx={{ marginTop: 0.5 }}>
                  <Typography
                    sx={{
                      fontWeight: '700',
                      letterSpacing: '0.46px',
                      marginRight: '5px',
                      textTransform: 'uppercase',
                      textDecoration: 'underline',
                      color: 'red',
                    }}
                  >
                    <hr />
                    DEPOSIT REQUIRED BEFORE BALANCE
                  </Typography>
                </Grid>
              )
          }
        </>
      ) : (
        <Grid item xs={6}>
          <BalanceButton sb={sb} setPopupPanelName={setPopupPanelName} />
        </Grid>
      )}
    </React.Fragment>
  )
}

BalanceOutstanding.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
}

export default BalanceOutstanding
