import React from 'react'
import { useRecordContext } from 'react-admin'

const VehicleDetails = () => {
  const record = useRecordContext()

  return (
    <React.Fragment>
      <span style={{ color: '#fc0', fontWeight: 'bold' }}>
        {record.quoteRequest.leadContact.vehicle.registration}
      </span>
      <br />
      {record.payload}
    </React.Fragment>
  )
}

export default VehicleDetails
