import React, { useState } from 'react'
import {
  useNotify,
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  TextField,
  useList,
  useGetList,
  useRecordContext,
  useUpdate,
  WithRecord,
  useRefresh,
} from 'react-admin'
import PropTypes, { bool } from 'prop-types'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { LockOpen, ModeEdit } from '@mui/icons-material'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { getNextLead } from '../../Utils/QuoteRequestUtils'
import { handleLeadLocking } from '../../Utils/RestUtils'
import SearchForm from '../../Components/Dashboard/SearchForm'
import VehicleDetails from '../../Components/Inbox/List/VehicleDetails'

export default function Dashboard() {
  const notify = useNotify()
  const refresh = useRefresh()
  const [update] = useUpdate()
  const userId = localStorage.getItem('user_id')
  const [disableGetLeadButton, setDisableGetLeadButton] = useState(false)

  const unlockLead = async (leadId, method) => {
    await handleLeadLocking(method, leadId).then(() => {
      refresh()
    })
  }

  const handleButtonClick = async () => {
    setDisableGetLeadButton(true)
    const result = await getNextLead()
    if (result.status === 'success') {
      location.href = '/#/quote-requests/%2Fapi%2Fquote-requests%2F' + result.id
    } else {
      notify(result.message, { type: 'error' })
      setDisableGetLeadButton(false)
    }
  }

  const ComponentWrapper = ({ title, children }) => {
    return (
      <Grid item xs={12} lg={6}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {title}
            </Typography>
            <Box
              sx={{
                backgroundColor: '#fff',
                border: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              {children}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  ComponentWrapper.propTypes = {
    title: PropTypes.string,
    children: React.Children,
  }

  const TableLayout = ({ showUnlockButton = false }) => {
    return (
      <>
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            sortable={false}
            label="Created at"
            render={() => (
              <React.Fragment>
                <DateField source="dateCreated" locales="en-GB" />
                <br />
                <Typography component="span">@&nbsp;</Typography>
                <DateField source="dateCreated" showTime showDate={false} />
              </React.Fragment>
            )}
          />
          <TextField
            source="leadContact.vehicle.registration"
            sortable={false}
            label="Reg"
            sx={{ textTransform: 'uppercase' }}
          />
          <TextField
            source="leadContact.vehicle.make"
            sortable={false}
            label="Make"
            sx={{ textTransform: 'uppercase' }}
          />
          <TextField
            source="leadContact.vehicle.model"
            sortable={false}
            label="Model"
            sx={{ textTransform: 'uppercase' }}
          />
          <WithRecord
            render={(record) => {
              const inUseBy = record.inUse
                ? record.inUse.replace('/api/users/', '')
                : null
              return (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <ViewQuoteButton />
                  <Button
                    label=""
                    startIcon={<LockOpen sx={{ mr: '-10px' }} />}
                    size="small"
                    onClick={() => {
                      unlockLead(record.originId, 'unlock')
                    }}
                    sx={{
                      display:
                        inUseBy === userId && showUnlockButton
                          ? 'block'
                          : 'none',
                    }}
                  />
                </Box>
              )
            }}
          />
        </Datagrid>
      </>
    )
  }

  TableLayout.propTypes = {
    showUnlockButton: bool,
  }

  const ViewQuoteButton = () => {
    const record = useRecordContext()
    let id = record.originId
    if (record.quoteRequest) {
      id = record.quoteRequest.id
    }

    const onClickHandler = async (record, id) => {
      if (record.quoteRequest) {
        update('lead-notifications', {
          id: record['@id'],
          data: { dismissed: true },
          previousData: record,
        })
      }

      window.open(
        '/#/quote-requests/' + encodeURIComponent('/api/quote-requests/' + id)
      )
    }

    return (
      <Button
        label="View"
        size="small"
        startIcon={<ModeEdit sx={{ mr: '-10px' }} />}
        onClick={() => onClickHandler(record, id)}
      />
    )
  }

  // Offer Accepted panel
  const OfferAcceptedComponent = () => {
    const { data, isPending } = useGetList('quote-requests', {
      pagination: { page: 1, perPage: 1000 },
      filter: {
        status: 'Offer accepted',
        mainUser: userId,
        archived: false,
        'tags.tagName': 'DISTRO',
      },
    })

    const listContext = useList({
      data,
      isPending,
      perPage: 10,
    })

    // Dont show section if data empty
    if (isPending || data === undefined || data.length === 0) {
      return <></>
    }

    return (
      <ComponentWrapper title={'Offer accepted'}>
        <ListContextProvider value={listContext}>
          <TableLayout />
        </ListContextProvider>
      </ComponentWrapper>
    )
  }

  // High Cap panel
  const HighCapComponent = () => {
    const { data, isPending } = useGetList('quote-requests', {
      pagination: { page: 1, perPage: 1000 },
      filter: {
        status: 'New inquiry',
        mainUser: userId,
        archived: false,
        'tags.tagName': 'DISTRO',
      },
      sort: {
        field: 'leadContact.vehicle.vehicleSalvage.currentCapValue',
        order: 'DESC',
      },
    })

    const listContext = useList({
      data,
      isPending,
      perPage: 10,
    })

    // Dont show section if data empty
    if (isPending || data === undefined || data.length === 0) {
      return <></>
    }

    return (
      <ComponentWrapper title={'High cap'}>
        <ListContextProvider value={listContext}>
          <TableLayout />
        </ListContextProvider>
      </ComponentWrapper>
    )
  }

  // Locked Leads panel
  const LockedLeadsComponent = () => {
    const { data, isPending } = useGetList('quote-requests', {
      pagination: { page: 1, perPage: 1000 },
      filter: { 'inUse.id': userId },
    })

    const listContext = useList({
      data,
      isPending,
      perPage: 10,
    })

    // Dont show section if data empty
    if (isPending || data === undefined || data.length === 0) {
      return <></>
    }

    return (
      <ComponentWrapper title={'Current leads you are locked in'}>
        <ListContextProvider value={listContext}>
          <TableLayout showUnlockButton={true} />
        </ListContextProvider>
      </ComponentWrapper>
    )
  }

  // Notifications panel
  const NotificationsComponent = () => {
    const { data, isPending } = useGetList('lead-notifications', {
      pagination: { page: 1, perPage: 1000 },
      filter: {
        'admin.id': userId,
        displayDropped: false,
        dismissed: false,
        noteType: 1,
        payload: 'Follow up',
      },
    })

    const listContext = useList({
      data,
      isPending,
      perPage: 10,
    })

    // Dont show section if data empty
    if (isPending || data === undefined || data.length === 0) {
      return <></>
    }

    return (
      <ComponentWrapper title={'Your Priority notifications'}>
        <ListContextProvider value={listContext}>
          <Datagrid bulkActionButtons={false}>
            <VehicleDetails label="Vehicle details" />

            <TextField label="Note" source="payload" />
            <DateField
              label="Due time"
              source="targetTimestamp"
              showTime={true}
              locale="en-GB"
              sortable={false}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
              <ViewQuoteButton />
            </Box>
          </Datagrid>
        </ListContextProvider>
      </ComponentWrapper>
    )
  }

  const InboundNotificationsComponent = () => {
    const { data, isPending } = useGetList('lead-notifications', {
      pagination: { page: 1, perPage: 1000 },
      filter: {
        'admin.id': userId,
        displayDropped: false,
        dismissed: false,
        noteType: 1,
        payload: 'Inbound:',
      },
    })

    const listContext = useList({
      data,
      isPending,
      perPage: 10,
    })

    // Dont show section if data empty
    if (isPending || data === undefined || data.length === 0) {
      return <></>
    }

    return (
      <ComponentWrapper title={'Inbound Notifications'}>
        <ListContextProvider value={listContext}>
          <Datagrid bulkActionButtons={false}>
            <VehicleDetails label="Vehicle details" />

            <TextField label="Note" source="payload" />
            <DateField
              label="Due time"
              source="targetTimestamp"
              showTime={true}
              locale="en-GB"
              sortable={false}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
              <ViewQuoteButton />
            </Box>
          </Datagrid>
        </ListContextProvider>
      </ComponentWrapper>
    )
  }

  return (
    <CardContent>
      <SearchForm />
      <Grid container columnSpacing={2} rowSpacing={2} mt={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ mb: 1 }}>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Get new lead
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    disabled={disableGetLeadButton}
                    endIcon={<ArrowForwardIosRoundedIcon />}
                    onClick={handleButtonClick}
                  >
                    Get lead
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <OfferAcceptedComponent />
        <HighCapComponent />
        <NotificationsComponent />
        <InboundNotificationsComponent />
        <LockedLeadsComponent />
      </Grid>
    </CardContent>
  )
}
